<template>
    <ODialog v-model:show="show" static :title="$t('Bulk updating...')" :noCloseButton="!bulkProgress.done"
        disableResize @hidden="$emit('hide')">
        <div class="o365-dialog-body">
            <div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :aria-valuenow="progress" aria-valuemin="0"
                        aria-valuemax="100" :style="{ 'width': progress + '%' }">
                        {{ Math.round(progress) }}%
                    </div>
                </div>
                <div class="text-center">
                    <small>{{ $t('Updating {$1} rows ({$2} of {$1} done)', {
                        $1: $formatNumber(bulkProgress.totalRows, '1 234'),
                        $2: $formatNumber(bulkProgress.updatedRows, '1 234')
                    })
                        }}</small>
                </div>
                <div v-if="bulkProgress.error" class="text-center text-danger">
                    <span>{{ bulkProgress.error }}</span>
                </div>
            </div>
        </div>
        <div v-if="bulkProgress.done" class="o365-dialog-footer">
            <button class="btn btn-sm btn-outline-primary" @click="() => show = false">
                {{ $t('Close') }}
            </button>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { ODialog } from 'o365-ui-components';
import { onMounted } from 'vue';
import { watch } from 'vue';

const props = defineProps<{
    bulkProgress: {
        totalRows: number,
        updatedRows: number,
        done: boolean,
        close: () => void,
        onDone: (pFn: () => void) => void,
        error?: string,
    }
}>();

const emit = defineEmits<{
    (e: 'hide'): void
}>();

const progress = computed(() => {
    return props.bulkProgress.updatedRows / props.bulkProgress.totalRows * 100;
});
const show = ref(false);

watch(() => props.bulkProgress.done, (pDone) => {
    if (pDone && !props.bulkProgress.error) {
        show.value = false;
    }
});

onMounted(() => {
    show.value = !props.bulkProgress.done;
    if (!show.value && !props.bulkProgress.error) {
        emit('hide');
    }

})

</script>